import React from "react";
import ReactSelect from "react-select";

const Select = ({ isMulti, name, onChange, choiceOptions, value, className, defaultChoice }) => {
    const styles = {
        control: (base) => ({
            ...base,
            backgroundColor: "white",
            borderColor: "lightgrey",
            borderRadius: 0,
            boxShadow: "none",
            fontSize: "14px",
            minHeight: "inherit",
            maxHeight: "30px",
            ":hover": { borderColor: "lightgrey" },
        }),
        singleValue: (base) => ({ ...base, top: "40%" }),
        dropdownIndicator: (base) => ({ ...base, color: "darkgrey", marginTop: "-3px" }),
        menu: (base) => ({ ...base, fontSize: "14px", borderRadius: 0, zIndex: "100", overflowX: "hidden" }),
        multiValue: (base) => ({ ...base, backgroundColor: "#fff", borderRadius: "0px" }),
        multiValueLabel: (base) => ({ ...base, borderRadius: "5px", color: "#333", fontSize: "1rem" }),
        multiValueRemove: (base) => ({ ...base, color: "#333" }),
        clearIndicator: () => ({ display: "none" }),
        indicatorSeparator: () => ({ display: "none" }),
        option: (base, { isFocused, isSelected }) => ({
            ...base,
            color: "#333",
            backgroundColor: "transparent",
            fontWeight: isSelected || isFocused ? "700" : "400",
        }),
    };

    const getValue = (val) => {
        return choiceOptions.find((option) => option.value === val) || undefined;
    };

    const customInputProps = {
        tabIndex: 2,
    };

    return (
        <ReactSelect
            inputProps={customInputProps}
            isMulti={isMulti}
            name={name}
            value={getValue(value)}
            options={choiceOptions}
            styles={styles}
            onChange={(e) => {
                onChange(e);
                // handleBlur();
            }}
            className={className}
            placeholder=""
            closeMenuOnSelect={true}
            openMenuOnFocus={true}
            tabIndex={0}
        />
    );
};

export default Select;
