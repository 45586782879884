import React, { useEffect, useReducer } from "react";
import { FieldsCollection } from "../fields/FieldsCollection";
import { countriesList } from "../constants/countries";
import { isUpdateable } from "../functions/fucntions";

const reducer = (state, action) => {
    return { ...state, [action.type]: action.payload };
};

let CustomerInfoForm = ({ response, ...props }) => {
    const [state, dispatch] = useReducer(reducer, {});
    const fetchedCustomer = response?.body?.customer || false;
    const fetchedCode = Number(response?.body?.code) || 0;

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (props?.onEnterpress && props.input.value.length !== 0) {
                props.onEnterpress();
            }
        }
    };

    useEffect(() => {
        const obj = { ...state };
        const nonEmptyData = Object.keys(obj).reduce((result, key) => {
            if (obj[key] !== "") {
                result[key] = obj[key];
            }
            return result;
        }, {});
        props.input.onChange(nonEmptyData);
    }, [state]);

    useEffect(() => {
        if (fetchedCustomer) {
            if (isUpdateable(fetchedCode)) {
            } else {
                for (const key in fetchedCustomer) {
                    dispatch({ type: key, payload: fetchedCustomer[key] });
                }
            }
        }
    }, [fetchedCustomer]);

    return (
        <>
            <div className="row" style={{}}>
                <FieldsCollection
                    name="firstName"
                    label="Vorname"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-6"
                    value={state["firstName"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
                <FieldsCollection
                    name="lastName"
                    label="Nachname"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-6"
                    value={state["lastName"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="row">
                <FieldsCollection
                    name="addressLine1"
                    label="Straße, Hausnummer"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-12"
                    value={state["addressLine1"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="row">
                <FieldsCollection
                    name="zip"
                    label="PLZ"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-3"
                    value={state["zip"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
                <FieldsCollection
                    name="city"
                    label="Ort"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-4"
                    value={state["city"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
                <FieldsCollection
                    name="country"
                    label="Land"
                    type="select"
                    choiceOptions={countriesList}
                    required={true}
                    mainClass="col-12 col-md-5"
                    value={state["country"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                />
            </div>
            <div className="row">
                <FieldsCollection
                    name="email"
                    label="E-Mail"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-12"
                    value={state["email"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
            </div>
            <div className="row">
                <FieldsCollection
                    name="mobilePhone"
                    label="Mobiltelefon"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-6"
                    value={state["mobilePhone"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
                <FieldsCollection
                    name="phone"
                    label="Telefon"
                    type="text"
                    required={true}
                    mainClass="col-12 col-md-6"
                    value={state["phone"] || ""}
                    onChange={dispatch}
                    showRequiredLabel={false}
                    inputExtraClass="sio-form-input"
                    labelExtraClass="sio-form-label"
                    onKeyDown={onKeyDown}
                />
            </div>
        </>
    );
};

export default CustomerInfoForm;
