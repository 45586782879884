import React, { useEffect, useState, useCallback } from "react";
import debounce from "debounce-promise";
import AsyncSelect from "react-select/async";

let AsyncChoice = ({
  id,
  label,
  placeholder,
  hideLabel,
  subType = "text",
  min,
  defaultValue,
  config,
  client,
  initialConditions,
  extraParams,
  ...props
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const onChange = (value) => {
    props.input.onChange(value);
    setFieldValue(value);
  };

  useEffect(() => {
    if (fieldValue) {
      props.onEnterpress();
    }
  }, [fieldValue]);

  const loadOptions = useCallback(
    debounce(async (inputValue) => {
      const conditions = {
        $and: [
          initialConditions,
          inputValue ? { [id]: { $match: inputValue } } : {},
        ],
      };

      const data = await client.get(
        `${props.modalId}conditions=${JSON.stringify(
          conditions
        )}&${extraParams}`,
        60
      );

      return data?.items?.length ? cleanData(data.items) : [];
    }, 500),
    [config]
  );

  const cleanData = (data) => {
    return data.map((val) => {
      return {
        label:
          val?.travelAgency?.agencyNumber + " - " + val?.travelAgency?.name,
        value: val?.agencyNumber,
      };
    });
  };

  const convertValue = useCallback(
    (value) => {
      onChange(value?.value);
    },
    [onChange]
  );

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (props?.onEnterpress && props.input.value.length !== 0) {
        props.onEnterpress();
      }
    }
  };

  useEffect(() => {
    if (defaultValue) {
      props.input.onChange(defaultValue);
    }
  }, [defaultValue]);

  const asyncStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base, state) => ({
      ...base,
      display: "flex",
      borderColor: "1px solid #ced4da",
      fontSize: "12px",
      padding: "0px",
      margin: "0px",
      borderColor: state.hover ? "0px" : "1px solid #ced4da!important",
      boxShadow: state.hover ? "none" : "",
    }),
    indicatorSeparator: (base, state) => ({
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: "2px!important",
      margin: "0px!important",
    }),
    clearIndicator: (base, state) => ({
      ...base,
      padding: "2px!important",
      margin: "0px!important",
    }),
  };
  return (
    <div className={`sio-input text-input ${props.class ?? ""}`}>
      {!hideLabel && (
        <label className="sio-form-label" htmlFor={id}>
          {label}
        </label>
      )}

      <AsyncSelect
        className="react-choice-element"
        classNamePrefix="react-choice-element"
        cacheOptions
        defaultOptions
        isClearable
        styles={asyncStyles}
        menuPortalTarget={document.body}
        isMulti={false}
        loadOptions={loadOptions}
        onChange={convertValue}
        placeholder={placeholder}
      />
    </div>
  );
};

export default AsyncChoice;
