import React, {  useState, useEffect } from "react";
import { Field } from "redux-form";
import ContineousForm from "../fields/ContineousForm";
import { formatDate, addOrformatDate, formatCurrency, formatLongDate, isUpdateable } from "../functions/fucntions";



const ServicesForm = ({ sendRequest, response, ...props }) => {
    const [formState, setFormState] = useState([]);

    const fetchedService = response?.body?.services || false;
    const fetchedCode = Number(response?.body?.code) || 0;

    const contineousFormFieldConfig = [
        { name: "selection", label: "M", flex: 0.8, type: "input", subType: "text", sendRequest: sendRequest },
        { name: "type", label: "Anf", flex: 1, type: "input", subType: "text", sendRequest: sendRequest, formatBeforeSubmit: (data)=> data.toString().toUpperCase() },
        {
            name: "key",
            label: "Leistungszeile",
            flex: 3,
            type: "input",
            subType: "text",
            sendRequest: sendRequest,
        },
        {
            name: "accommodation",
            label: "Unterbringung",
            flex: 2.2,
            type: "input",
            subType: "text",
            sendRequest: sendRequest,
            formatBeforeSubmit: (data)=> data.toString().toUpperCase()
        },
        {
            name: "participantAssignment",
            label: "Zuordnung",
            flex: 2,
            type: "input",
            subType: "text",
            sendRequest: sendRequest,
        },
        {
            name: "from",
            label: "Hin",
            flex: 1.5,
            type: "input",
            subType: "text",
            sendRequest: sendRequest,
            formatBeforeSubmit: formatDate,
        },
        {
            name: "to",
            label: "Rück",
            flex: 1.5,
            type: "input",
            subType: "text",
            sendRequest: sendRequest,
            formatBeforeSubmit: addOrformatDate,
        },
        { name: "price", label: "Preis", flex: 1.5, type: "static", hideInFormValue: true },
        { name: "status", label: "Status", flex: 1, type: "static", hideInFormValue: true },
    ];

    const makeKeyfromFetchedRow = (data) => {
        const fromDate = data?.from ? formatDate(data.from) :  "fromDate";
        const toDate = data?.to ? formatDate(data.to) : "toDate";
        return `${data.type}-${data.key}-${fromDate}-${toDate}`;
    };

    const makeKeyFromCurrentRow = (data) => {
        if (data.find((val) => val.name === "key")?.value || "") {
            const fromDate = formatDate(data.find((val) => val.name === "from")?.value || "");
            const toDate = addOrformatDate(data.find((val) => val.name === "to")?.value || "", data);
            const dataKey = data.find((val) => val.name === "key")?.value || "key";
            const dataType = data.find((val) => val.name === "type")?.value || "type";
            return `${dataType}-${dataKey}-${fromDate}-${toDate}`;
        } else {
            return "DONOTCHANGE";
        }
    };

    const updatePreviousData = (previousRowsData, fetchedData) =>
        previousRowsData.map((row) => {
            const fetchedRow = fetchedData.find((val) => makeKeyfromFetchedRow(val) === makeKeyFromCurrentRow(row));
            if (fetchedRow) {
                return row.map((field) => {
                    if (field.name === "price") {
                        return { ...field, value: formatCurrency(fetchedRow.price, fetchedRow?.amount) };
                    }
                    if (field.name === "accommodation") {
                        return { ...field, value: fetchedRow?.accommodation };
                    }
                    if (field.name === "from" || field.name === "to") {
                        return { ...field, value: formatLongDate(fetchedRow[field.name]) };
                    }
                    if (field.name === "key" || field.name === "type") {
                        return { ...field, value: fetchedRow[field.name] };
                    }
                    return { ...field };
                });
            }
            return row;
        });

    const overideNewData = (fetchedData) =>
        fetchedData.map((service) =>
            contineousFormFieldConfig.map((field) => {
                const fieldValue = service[field.name];
                if (fieldValue !== undefined) {
                    return {
                        ...field,
                        value:
                            field.name === "price"
                                ? formatCurrency(fieldValue)
                                : field.name === "from" || field.name === "to"
                                ? formatLongDate(fieldValue)
                                : fieldValue,
                    };
                }
                return field;
            })
        );

    useEffect(() => {
        if (fetchedService) {
            if (isUpdateable(fetchedCode) && fetchedService.length <= formState.length ) {
                setFormState((previousRowsData) => updatePreviousData(previousRowsData, fetchedService));
            } else if (fetchedService.length > 0) {
                setFormState(overideNewData(fetchedService));
            }
        }
    }, [fetchedService]);

    return (
        <div className="sio-services with-background" style={{ maxHeight: "200px", overflowY: "auto" }}>
            <Field
                id="services"
                name="services"
                onEnterPress={sendRequest}
                defaultEmptyRows={3}
                fieldsConfig={contineousFormFieldConfig}
                addMoreLabel={"Hinzufügen"}
                component={ContineousForm}
                showActions={true}
                showDeleteRowButton={true}
                formState={formState}
                setFormState={setFormState}
                {...props}
            />
        </div>
    );
};

export default ServicesForm;
