import React, { useEffect } from "react";
import Select from "./select";



export const FieldsCollection = ({
    name,
    label,
    type = "text",
    required = false,
    mainClass,
    placeholder = "",
    hideLabel,
    choiceOptions = [],
    defaultChoice = "",
    value,
    onChange,
    showRequiredLabel = true,
    inputExtraClass = "",
    labelExtraClass = "",
    ...props
}) => {
    useEffect(() => {
        // for default values of form fields
        // if (type === "select" && defaultChoice) {
        //     onChange({ type: name, payload: defaultChoice });
        // } else {
        //     onChange({ type: name, payload: "" });
        // }
    }, []);
    return (
        <div className={` ${mainClass}`}>
            {!hideLabel && (
                <label className={"form-field-label " + labelExtraClass} htmlFor={name}>
                    {label}
                    {showRequiredLabel && required && (
                        <span className="form-required" title="Diese Angabe wird benötigt.">
                            {" "}
                            *
                        </span>
                    )}
                </label>
            )}
            {(type == "text" || type == "number" || type == "email" || type == "date" || type == "password") && (
                <input
                    className={"form-control " + inputExtraClass}
                    id={name}
                    onChange={(e) => onChange({ type: name, payload: e.target.value })}
                    value={value}
                    data-lpignore="true"
                    {...props}
                />
            )}

            {type == "select" && (
                <Select
                    choiceOptions={choiceOptions}
                    name={name}
                    onChange={(e) => {
                        onChange({ type: name, payload: e.value });
                    }}
                    value={value}
                    isMulti={false}
                    defaultChoice={defaultChoice}
                />
            )}

            {type == "textarea" && (
                <textarea
                    className={"form-control " + inputExtraClass}
                    id={name}
                    onChange={(e) => onChange({ type: name, payload: e.target.value })}
                    style={{ height: "160px" }}
                    {...props}
                ></textarea>
            )}

            {type == "radio" && (
                <div className="d-flex flex-align-center flex-gap-3 flex-wrap">
                    {choiceOptions.map((option) => (
                        <div className="radio" key={option.value}>
                            <input
                                checked={value == option.value}
                                className="mt-1 mr-1"
                                type="radio"
                                id={option.value}
                                name={name}
                                value={option.value}
                                onChange={(e) => onChange({ type: name, payload: option.value })}
                                {...props}
                            ></input>
                            <label className="text-bold font-size-2" htmlFor={option.label}>
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
