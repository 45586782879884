import React, { ComponentType, FC, useEffect, useState } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import GeneralInfoRow from "./sub-parts/GeneralInfoRow";
import ServicesForm from "./sub-parts/ServicesForm";
import CustomerInfoForm from "./sub-parts/CustomerInfoForm";
import PersonsInfoForm from "./sub-parts/PersonsInfoForm";
import { connect } from "react-redux";
import {
  makeRequestData,
  postToServer,
  formatCurrency,
} from "./functions/fucntions";

let FastBookingForm = ({
  handleSubmit,
  formValues = "",
  tomaMask,
  client,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [errorResponse, setErrorResponse] = useState("");

  const code = response?.body?.code || "";
  const readableCode = response?.body?.readableCode || "";
  const total = response?.body?.total || 0;

  const sendRequest = async () => {
    setLoading(true);
    const response = await postToServer(
      makeRequestData(formValues, tomaMask),
      client
    );
    setResponse(response);
    if (response?.applicationHeader?.bodyType) {
      if (parseInt(response?.applicationHeader?.bodyType || 0) === 51) {
        setErrorResponse(response.body);
      } else {
        setErrorResponse("");
      }
    } else {
      setErrorResponse(response);
    }
    setLoading(false);
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const responseRemarks =
    response?.body?.remarks || response?.body?.informationLines || [];

  const responseText = () => {
    if (errorResponse) {
      if (parseInt(errorResponse.code) === 400) {
        return errorResponse.message;
      }

      if (parseInt(errorResponse.code) === 998) {
        return errorResponse.messageText;
      }
      const remarks = response?.body?.remarks || [];
      return remarks.join("\n");
    }
    const remarks = response?.body?.informationLines || [];
    return remarks.join("\n");
  };


  return (
    <div>
      <div className="with-background"></div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={onKeyDown}
        style={{ opacity: loading ? 0.5 : 1 }}
        autoComplete="off"
      >
        <GeneralInfoRow
          tomaMask={tomaMask}
          fieldChanger={props.change}
          response={response}
          sendRequest={sendRequest}
          client={client}
        />
        <div className="mt-3 mb-3">
          <ServicesForm response={response} sendRequest={sendRequest} />
        </div>
        {total !== 0 && (
          <div className="total-row">
            <span className="mr-2">Gesamt: </span>
            <span>{formatCurrency(total)}</span>
          </div>
        )}
        {code && (
          <div className={"mt-2 mb-2 code-header"}>
            <span
              className="content"
              style={{ backgroundColor: code === 200 || code === 201 ? "lightGreen" : "" }}
            >
              Code: {code} - {readableCode.replaceAll("_", " ")}
            </span>
          </div>
        )}
        <div className="mt-2">
          <textarea tabIndex={-1} readOnly value={responseText()} />
        </div>
        <div
          className="mt-3 with-background"
          style={{ display: "flex", gap: "12px" }}
        >
          <div style={{ flex: 3 }}>
            <Field
              name="customer"
              response={response}
              onEnterpress={sendRequest}
              component={CustomerInfoForm}
            />
          </div>
          <div style={{ flex: 5 }}>
            <PersonsInfoForm response={response} sendRequest={sendRequest} />
          </div>
        </div>
      </form>
      <div className="mt-3">
        <button
          className="btn btn-primary mr-2"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reset
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            sendRequest();
          }}
        >
          Anfrage senden
        </button>
      </div>
    </div>
  );
};
FastBookingForm = reduxForm({
  form: "fast-booking",
})(FastBookingForm);

FastBookingForm = connect((state) => {
  return { formValues: getFormValues("fast-booking")(state) };
})(FastBookingForm);

export default FastBookingForm;
