import {customElement, bindable, inject} from "aurelia-framework";
import {Client} from '../api/client';
import {EventAggregator} from "aurelia-event-aggregator";

@customElement('sio-todo-due-badge')
@inject(Client, EventAggregator)
export class OverdueTodo
{
    overdueTodos = 0;

    @bindable modelId;

    constructor(client, ea)
    {
        this.client = client;
        this.ea = ea;
        this.modelId = 'todo/todo-entry';
    }

    attached()
    {
        this.load();

        this.subscription = this.ea.subscribe('sio_form_post_submit', (response) => {

            if (response?.config?.modelId === this.modelId) {
                this.load();
            }
        });
    }

    detached()
    {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    load()
    {
        this.client.get(this.modelId + '/dues').then((data) => {
            this.overdueTodos = data;
        });
    }

}
