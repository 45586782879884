import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.locale("de");
// import client from "./ApiClient";

export const postToServer = async (data, client) => {
  let username = "sio";
  let password = "vEmxTHYF";
  let auth = btoa(`${username}:${password}`);
  try {
    const response = await client.request(
      "post",
      "tourism/booking-api",
      data,
      {},
      { headers: { Authorization: `Basic ${auth}` } }
    );
    if (response.ok) {
      return response.data;
    } else {
      const errorBody = response?.data || response?.message || response?.code;
      throw errorBody;
    }
  } catch (error) {
    return error?.data;
  }
};

export const makeRequestData = (formValues, tomaMask) => {
  const crsHeader = {
    crs: tomaMask?.crs || "",
  };
  const applicationHeader = {
    bodyType: "T1",
    terminalNumber: "123",
    agencyNumber: formValues?.agencyNumber || "",
    businessSection: "123",
  };

  const services = formValues.services;
  const persons = formValues.persons;
  const customer = formValues.customer;

  let body = {
    country: "DE",
    operator: formValues.operator,
    currency: "EUR",
    noInsuranceWanted: true,
    services: services,
    persons: persons,
    customer: customer,
  };

  if (formValues.requestType) {
    body.requestType = formValues.requestType;
  }
  if (formValues.operator) {
    body.operator = formValues.operator;
  }
  if (formValues.journeyType) {
    body.journeyType = formValues.journeyType;
  }
  if (formValues.countPersons) {
    body.countPersons = formValues.countPersons;
  }

  if (formValues.orderNumber) {
    body.orderNumber = formValues.orderNumber;
  }

  return {
    crsHeader,
    applicationHeader,
    body,
  };
};
export const formatDate = (dateString, row = {}) => {
  let date;
  if (!dateString) {
    return "";
  }
  if (!formatDate?.length) {
    return false;
  }
  if (dateString?.length < 4) {
    return "";
  }
  const hasPeriods = dateString.includes(".");
  if (dateString.length === 4) {
    // DDMM
    date = new Date(
      new Date().getFullYear(),
      parseInt(dateString.substr(2), 10) - 1,
      parseInt(dateString.substr(0, 2), 10)
    );
  } else if (dateString.length === 6) {
    // DDMMYY
    date = new Date(
      parseInt(dateString.substr(4, 2), 10) + 2000,
      parseInt(dateString.substr(2, 2), 10) - 1,
      parseInt(dateString.substr(0, 2), 10)
    );
  } else if (dateString.length === 8 && !hasPeriods) {
    // DDMMYYYY
    date = new Date(
      parseInt(dateString.substr(4, 4), 10),
      parseInt(dateString.substr(2, 2), 10) - 1,
      parseInt(dateString.substr(0, 2), 10)
    );
  } else if (dateString.length === 8 && hasPeriods) {
    // DD.MM.YY
    const parts = dateString.split(".");
    date = new Date(
      parseInt(parts[2], 10) + 2000,
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
  } else if (dateString.length === 10 && hasPeriods) {
    // DD.MM.YYYY
    const parts = dateString.split(".");
    date = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
  } else if (dateString.length === 5) {
    // DMMYY
    date = new Date(
      parseInt(dateString.substr(3, 4), 10),
      parseInt(dateString.substr(0, 2), 10) - 1,
      1
    );
    return (
      date?.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) || ""
    );
  } else if (dateString.length === 25) {
    // DMMYY
    date = new Date(dateString);
    return (
      date?.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) || ""
    );
  }

  // Format the date as DD.MM.YYYY and return it as a string

  return (
    date?.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }) || ""
  );
};

export const addOrformatDate = (dateString, row) => {
  if (!dateString) {
    return "";
  }
  if (dateString.toString().length < 4) {
    const fromDate = row.find((val) => val.name === "from")?.value;
    const formatFromDate = formatDate(fromDate);
    const dayJsParssed = dayjs(formatFromDate, "DD.MM.YYYY");
    return dayJsParssed
      .add(parseInt(dateString.toString()), "day")
      .format("DD.MM.YYYY");
  }
  return formatDate(dateString);
};
export const formatLongDate = (dateString, forDom = false) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  if (forDom) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}.${month}.${year}`;
  }
};

export const formatCurrency = (money, count = 1) => {
  if (money == null) {
    return "";
  }
  if (typeof money !== "number") {
    const amount = money.amount / (100 * count);
    if (typeof Intl === "undefined") {
      return amount;
    }
    const options = { style: "currency", currency: money.currency };
    const formatter = new Intl.NumberFormat("de-DE", options);
    return formatter.format(amount);
  }
};

export const isUpdateable = (fetchedCode) => {
  if (
    fetchedCode === 726 ||
    (fetchedCode !== 200 &&
      fetchedCode !== 205 &&
      fetchedCode !== 201 &&
      fetchedCode !== 52 &&
      fetchedCode !== 0)
  ) {
    return true;
  } else {
    return false;
  }
};
