export const countriesList = [
    {
        label: "Deutschland",
        value: "DE",
    },
    {
        label: "Belgien",
        value: "BE",
    },
    {
        label: "Italien",
        value: "IT",
    },
    {
        label: "Luxemburg",
        value: "LU",
    },
    {
        label: "Niederlande",
        value: "NL",
    },
    {
        label: "Österreich",
        value: "AT",
    },
    {
        label: "Schweiz",
        value: "CH",
    },

    {
        label: "Afghanistan",
        value: "AF",
    },
    {
        label: "Ägypten",
        value: "EG",
    },
    {
        label: "Ålandinseln",
        value: "AX",
    },
    {
        label: "Albanien",
        value: "AL",
    },
    {
        label: "Algerien",
        value: "DZ",
    },
    {
        label: "Amerikanisch-Samoa",
        value: "AS",
    },
    {
        label: "Amerikanische Jungferninseln",
        value: "VI",
    },
    {
        label: "Amerikanische Überseeinseln",
        value: "UM",
    },
    {
        label: "Andorra",
        value: "AD",
    },
    {
        label: "Angola",
        value: "AO",
    },
    {
        label: "Anguilla",
        value: "AI",
    },
    {
        label: "Antarktis",
        value: "AQ",
    },
    {
        label: "Antigua und Barbuda",
        value: "AG",
    },
    {
        label: "Äquatorialguinea",
        value: "GQ",
    },
    {
        label: "Argentinien",
        value: "AR",
    },
    {
        label: "Armenien",
        value: "AM",
    },
    {
        label: "Aruba",
        value: "AW",
    },
    {
        label: "Aserbaidschan",
        value: "AZ",
    },
    {
        label: "Äthiopien",
        value: "ET",
    },
    {
        label: "Australien",
        value: "AU",
    },
    {
        label: "Bahamas",
        value: "BS",
    },
    {
        label: "Bahrain",
        value: "BH",
    },
    {
        label: "Bangladesch",
        value: "BD",
    },
    {
        label: "Barbados",
        value: "BB",
    },
    {
        label: "Belarus",
        value: "BY",
    },
    {
        label: "Belgien",
        value: "BE",
    },
    {
        label: "Belize",
        value: "BZ",
    },
    {
        label: "Benin",
        value: "BJ",
    },
    {
        label: "Bermuda",
        value: "BM",
    },
    {
        label: "Bhutan",
        value: "BT",
    },
    {
        label: "Bolivien",
        value: "BO",
    },
    {
        label: "Bosnien und Herzegowina",
        value: "BA",
    },
    {
        label: "Botsuana",
        value: "BW",
    },
    {
        label: "Bouvetinsel",
        value: "BV",
    },
    {
        label: "Brasilien",
        value: "BR",
    },
    {
        label: "Britische Jungferninseln",
        value: "VG",
    },
    {
        label: "Britisches Territorium im Indischen Ozean",
        value: "IO",
    },
    {
        label: "Brunei Darussalam",
        value: "BN",
    },
    {
        label: "Bulgarien",
        value: "BG",
    },
    {
        label: "Burkina Faso",
        value: "BF",
    },
    {
        label: "Burundi",
        value: "BI",
    },
    {
        label: "Cabo Verde",
        value: "CV",
    },
    {
        label: "Chile",
        value: "CL",
    },
    {
        label: "China",
        value: "CN",
    },
    {
        label: "Cookinseln",
        value: "CK",
    },
    {
        label: "Costa Rica",
        value: "CR",
    },
    {
        label: "Côte d’Ivoire",
        value: "CI",
    },
    {
        label: "Curaçao",
        value: "CW",
    },
    {
        label: "Dänemark",
        value: "DK",
    },
    {
        label: "Dominica",
        value: "DM",
    },
    {
        label: "Dominikanische Republik",
        value: "DO",
    },
    {
        label: "Dschibuti",
        value: "DJ",
    },
    {
        label: "Ecuador",
        value: "EC",
    },
    {
        label: "El Salvador",
        value: "SV",
    },
    {
        label: "Eritrea",
        value: "ER",
    },
    {
        label: "Estland",
        value: "EE",
    },
    {
        label: "Eswatini",
        value: "SZ",
    },
    {
        label: "Falklandinseln",
        value: "FK",
    },
    {
        label: "Färöer",
        value: "FO",
    },
    {
        label: "Fidschi",
        value: "FJ",
    },
    {
        label: "Finnland",
        value: "FI",
    },
    {
        label: "Frankreich",
        value: "FR",
    },
    {
        label: "Französisch-Guayana",
        value: "GF",
    },
    {
        label: "Französisch-Polynesien",
        value: "PF",
    },
    {
        label: "Französische Süd- und Antarktisgebiete",
        value: "TF",
    },
    {
        label: "Gabun",
        value: "GA",
    },
    {
        label: "Gambia",
        value: "GM",
    },
    {
        label: "Georgien",
        value: "GE",
    },
    {
        label: "Ghana",
        value: "GH",
    },
    {
        label: "Gibraltar",
        value: "GI",
    },
    {
        label: "Grenada",
        value: "GD",
    },
    {
        label: "Griechenland",
        value: "GR",
    },
    {
        label: "Grönland",
        value: "GL",
    },
    {
        label: "Guadeloupe",
        value: "GP",
    },
    {
        label: "Guam",
        value: "GU",
    },
    {
        label: "Guatemala",
        value: "GT",
    },
    {
        label: "Guernsey",
        value: "GG",
    },
    {
        label: "Guinea",
        value: "GN",
    },
    {
        label: "Guinea-Bissau",
        value: "GW",
    },
    {
        label: "Guyana",
        value: "GY",
    },
    {
        label: "Haiti",
        value: "HT",
    },
    {
        label: "Heard und McDonaldinseln",
        value: "HM",
    },
    {
        label: "Honduras",
        value: "HN",
    },
    {
        label: "Indien",
        value: "IN",
    },
    {
        label: "Indonesien",
        value: "ID",
    },
    {
        label: "Irak",
        value: "IQ",
    },
    {
        label: "Iran",
        value: "IR",
    },
    {
        label: "Irland",
        value: "IE",
    },
    {
        label: "Island",
        value: "IS",
    },
    {
        label: "Isle of Man",
        value: "IM",
    },
    {
        label: "Israel",
        value: "IL",
    },
    {
        label: "Italien",
        value: "IT",
    },
    {
        label: "Jamaika",
        value: "JM",
    },
    {
        label: "Japan",
        value: "JP",
    },
    {
        label: "Jemen",
        value: "YE",
    },
    {
        label: "Jersey",
        value: "JE",
    },
    {
        label: "Jordanien",
        value: "JO",
    },
    {
        label: "Kaimaninseln",
        value: "KY",
    },
    {
        label: "Kambodscha",
        value: "KH",
    },
    {
        label: "Kamerun",
        value: "CM",
    },
    {
        label: "Kanada",
        value: "CA",
    },
    {
        label: "Karibische Niederlande",
        value: "BQ",
    },
    {
        label: "Kasachstan",
        value: "KZ",
    },
    {
        label: "Katar",
        value: "QA",
    },
    {
        label: "Kenia",
        value: "KE",
    },
    {
        label: "Kirgisistan",
        value: "KG",
    },
    {
        label: "Kiribati",
        value: "KI",
    },
    {
        label: "Kokosinseln",
        value: "CC",
    },
    {
        label: "Kolumbien",
        value: "CO",
    },
    {
        label: "Komoren",
        value: "KM",
    },
    {
        label: "Kongo-Brazzaville",
        value: "CG",
    },
    {
        label: "Kongo-Kinshasa",
        value: "CD",
    },
    {
        label: "Kroatien",
        value: "HR",
    },
    {
        label: "Kuba",
        value: "CU",
    },
    {
        label: "Kuwait",
        value: "KW",
    },
    {
        label: "Laos",
        value: "LA",
    },
    {
        label: "Lesotho",
        value: "LS",
    },
    {
        label: "Lettland",
        value: "LV",
    },
    {
        label: "Libanon",
        value: "LB",
    },
    {
        label: "Liberia",
        value: "LR",
    },
    {
        label: "Libyen",
        value: "LY",
    },
    {
        label: "Liechtenstein",
        value: "LI",
    },
    {
        label: "Litauen",
        value: "LT",
    },
    {
        label: "Luxemburg",
        value: "LU",
    },
    {
        label: "Madagaskar",
        value: "MG",
    },
    {
        label: "Malawi",
        value: "MW",
    },
    {
        label: "Malaysia",
        value: "MY",
    },
    {
        label: "Malediven",
        value: "MV",
    },
    {
        label: "Mali",
        value: "ML",
    },
    {
        label: "Malta",
        value: "MT",
    },
    {
        label: "Marokko",
        value: "MA",
    },
    {
        label: "Marshallinseln",
        value: "MH",
    },
    {
        label: "Martinique",
        value: "MQ",
    },
    {
        label: "Mauretanien",
        value: "MR",
    },
    {
        label: "Mauritius",
        value: "MU",
    },
    {
        label: "Mayotte",
        value: "YT",
    },
    {
        label: "Mexiko",
        value: "MX",
    },
    {
        label: "Mikronesien",
        value: "FM",
    },
    {
        label: "Monaco",
        value: "MC",
    },
    {
        label: "Mongolei",
        value: "MN",
    },
    {
        label: "Montenegro",
        value: "ME",
    },
    {
        label: "Montserrat",
        value: "MS",
    },
    {
        label: "Mosambik",
        value: "MZ",
    },
    {
        label: "Myanmar",
        value: "MM",
    },
    {
        label: "Namibia",
        value: "NA",
    },
    {
        label: "Nauru",
        value: "NR",
    },
    {
        label: "Nepal",
        value: "NP",
    },
    {
        label: "Neukaledonien",
        value: "NC",
    },
    {
        label: "Neuseeland",
        value: "NZ",
    },
    {
        label: "Nicaragua",
        value: "NI",
    },
    {
        label: "Niederlande",
        value: "NL",
    },
    {
        label: "Niger",
        value: "NE",
    },
    {
        label: "Nigeria",
        value: "NG",
    },
    {
        label: "Niue",
        value: "NU",
    },
    {
        label: "Nordkorea",
        value: "KP",
    },
    {
        label: "Nördliche Marianen",
        value: "MP",
    },
    {
        label: "Nordmazedonien",
        value: "MK",
    },
    {
        label: "Norfolkinsel",
        value: "NF",
    },
    {
        label: "Norwegen",
        value: "NO",
    },
    {
        label: "Oman",
        value: "OM",
    },
    {
        label: "Österreich",
        value: "AT",
    },
    {
        label: "Pakistan",
        value: "PK",
    },
    {
        label: "Palästinensische Autonomiegebiete",
        value: "PS",
    },
    {
        label: "Palau",
        value: "PW",
    },
    {
        label: "Panama",
        value: "PA",
    },
    {
        label: "Papua-Neuguinea",
        value: "PG",
    },
    {
        label: "Paraguay",
        value: "PY",
    },
    {
        label: "Peru",
        value: "PE",
    },
    {
        label: "Philippinen",
        value: "PH",
    },
    {
        label: "Pitcairninseln",
        value: "PN",
    },
    {
        label: "Polen",
        value: "PL",
    },
    {
        label: "Portugal",
        value: "PT",
    },
    {
        label: "Puerto Rico",
        value: "PR",
    },
    {
        label: "Republik Moldau",
        value: "MD",
    },
    {
        label: "Réunion",
        value: "RE",
    },
    {
        label: "Ruanda",
        value: "RW",
    },
    {
        label: "Rumänien",
        value: "RO",
    },
    {
        label: "Russland",
        value: "RU",
    },
    {
        label: "Salomonen",
        value: "SB",
    },
    {
        label: "Sambia",
        value: "ZM",
    },
    {
        label: "Samoa",
        value: "WS",
    },
    {
        label: "San Marino",
        value: "SM",
    },
    {
        label: "São Tomé und Príncipe",
        value: "ST",
    },
    {
        label: "Saudi-Arabien",
        value: "SA",
    },
    {
        label: "Schweden",
        value: "SE",
    },
    {
        label: "Schweiz",
        value: "CH",
    },
    {
        label: "Senegal",
        value: "SN",
    },
    {
        label: "Serbien",
        value: "RS",
    },
    {
        label: "Seychellen",
        value: "SC",
    },
    {
        label: "Sierra Leone",
        value: "SL",
    },
    {
        label: "Simbabwe",
        value: "ZW",
    },
    {
        label: "Singapur",
        value: "SG",
    },
    {
        label: "Sint Maarten",
        value: "SX",
    },
    {
        label: "Slowakei",
        value: "SK",
    },
    {
        label: "Slowenien",
        value: "SI",
    },
    {
        label: "Somalia",
        value: "SO",
    },
    {
        label: "Sonderverwaltungsregion Hongkong",
        value: "HK",
    },
    {
        label: "Sonderverwaltungsregion Macau",
        value: "MO",
    },
    {
        label: "Spanien",
        value: "ES",
    },
    {
        label: "Spitzbergen und Jan Mayen",
        value: "SJ",
    },
    {
        label: "Sri Lanka",
        value: "LK",
    },
    {
        label: "St. Barthélemy",
        value: "BL",
    },
    {
        label: "St. Helena",
        value: "SH",
    },
    {
        label: "St. Kitts und Nevis",
        value: "KN",
    },
    {
        label: "St. Lucia",
        value: "LC",
    },
    {
        label: "St. Martin",
        value: "MF",
    },
    {
        label: "St. Pierre und Miquelon",
        value: "PM",
    },
    {
        label: "St. Vincent und die Grenadinen",
        value: "VC",
    },
    {
        label: "Südafrika",
        value: "ZA",
    },
    {
        label: "Sudan",
        value: "SD",
    },
    {
        label: "Südgeorgien und die Südlichen Sandwichinseln",
        value: "GS",
    },
    {
        label: "Südkorea",
        value: "KR",
    },
    {
        label: "Südsudan",
        value: "SS",
    },
    {
        label: "Suriname",
        value: "SR",
    },
    {
        label: "Syrien",
        value: "SY",
    },
    {
        label: "Tadschikistan",
        value: "TJ",
    },
    {
        label: "Taiwan",
        value: "TW",
    },
    {
        label: "Tansania",
        value: "TZ",
    },
    {
        label: "Thailand",
        value: "TH",
    },
    {
        label: "Timor-Leste",
        value: "TL",
    },
    {
        label: "Togo",
        value: "TG",
    },
    {
        label: "Tokelau",
        value: "TK",
    },
    {
        label: "Tonga",
        value: "TO",
    },
    {
        label: "Trinidad und Tobago",
        value: "TT",
    },
    {
        label: "Tschad",
        value: "TD",
    },
    {
        label: "Tschechien",
        value: "CZ",
    },
    {
        label: "Tunesien",
        value: "TN",
    },
    {
        label: "Türkei",
        value: "TR",
    },
    {
        label: "Turkmenistan",
        value: "TM",
    },
    {
        label: "Turks- und Caicosinseln",
        value: "TC",
    },
    {
        label: "Tuvalu",
        value: "TV",
    },
    {
        label: "Uganda",
        value: "UG",
    },
    {
        label: "Ukraine",
        value: "UA",
    },
    {
        label: "Ungarn",
        value: "HU",
    },
    {
        label: "Uruguay",
        value: "UY",
    },
    {
        label: "Usbekistan",
        value: "UZ",
    },
    {
        label: "Vanuatu",
        value: "VU",
    },
    {
        label: "Vatikanstadt",
        value: "VA",
    },
    {
        label: "Venezuela",
        value: "VE",
    },
    {
        label: "Vereinigte Arabische Emirate",
        value: "AE",
    },
    {
        label: "Vereinigte Staaten",
        value: "US",
    },
    {
        label: "Vereinigtes Königreich",
        value: "GB",
    },
    {
        label: "Vietnam",
        value: "VN",
    },
    {
        label: "Wallis und Futuna",
        value: "WF",
    },
    {
        label: "Weihnachtsinsel",
        value: "CX",
    },
    {
        label: "Westsahara",
        value: "EH",
    },
    {
        label: "Zentralafrikanische Republik",
        value: "CF",
    },
    {
        label: "Zypern",
        value: "CY",
    },
    {
        label: "Kosovo",
        value: "XK",
    },
];
