import React, {  useEffect } from "react";
import { Field } from "redux-form";
import Input from "../fields/input";
import { isUpdateable } from "../functions/fucntions";
import AsyncChoice from "../fields/AsyncChoice";

const GeneralInfoRow = ({ sendRequest, response, fieldChanger,tomaMask,client, ...props }) => {
    const fetchedCode = Number(response?.body?.code) || 0;
    const responseBody = response?.body || false;

    useEffect(() => {
        if (isUpdateable(fetchedCode)) {
        } else {
            if (responseBody?.countPersons) {
                fieldChanger("countPersons", Number(responseBody.countPersons));
            }
            if (responseBody?.operator) {
                fieldChanger("operator", responseBody.operator);
            }
            if (responseBody?.journeyType) {
                fieldChanger("operator", responseBody.journeyType);
            }
            if (responseBody?.orderNumber) {
                fieldChanger("orderNumber", responseBody.orderNumber);
            }
            if (responseBody?.mfl) {
                fieldChanger("mfl", responseBody.mfl);
            }
            if (responseBody?.agencyNumber) {
              fieldChanger("agencyNumber", responseBody.agencyNumber);
            }
        }
    }, [responseBody]);

    return (
        <div className="with-background">
            <div className="top-row">
                <Field
                    id="Aktion"
                    name="requestType"
                    label={"Aktion"}
                    onEnterpress={sendRequest}
                    component={Input}
                    {...props}
                />
                <Field
                    id="Veran"
                    name="operator"
                    label={"Veran"}
                    onEnterpress={sendRequest}
                    component={Input}
                    defaultValue={tomaMask.operator}
                    {...props}
                />
                <Field
                    id="Reiseart"
                    name="journeyType"
                    label={"Reiseart"}
                    onEnterpress={sendRequest}
                    component={Input}
                    {...props}
                />
                <Field
                    id="Pers"
                    min={1}
                    name="countPersons"
                    label={"Pers"}
                    onEnterpress={sendRequest}
                    component={Input}
                    subType={"number"}
                    {...props}
                />
                <Field
                    id="agencyNumber"
                    name="agencyNumber"
                    label={"Agentur"}
                    component={AsyncChoice}
                    onEnterpress={sendRequest}
                    config={{
                        modelId: "tourism-stadis/crs-agency-number",
                      }}
                    client={client}
                    initialConditions={{crs:{$in:["string"]}}}
                    extraParams={"embeds[]=travelAgency&loadCount:false"}
                    modalId={"tourism-stadis/crs-agency-number?"}
                    placeholder="(bitte auswählen)"
                    {...props}
                />
                <Field
                    id="Vorgang"
                    name="orderNumber"
                    label={"Vorgang"}
                    onEnterpress={sendRequest}
                    component={Input}
                    {...props}
                />
            </div>
            <Field
                id="mfl"
                name="mfl"
                label={"-"}
                hideLabel={true}
                class="mt-1"
                onEnterpress={sendRequest}
                component={Input}
                {...props}
            />
        </div>
    );
};

export default GeneralInfoRow;
