import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Select from "./select";


const ACTION_BUTTON_WIDTH = "30px";

const ContineousForm = ({
    name,
    fieldsConfig,
    defaultEmptyRows,
    showActions = true,
    showDeleteRowButton = true,
    showDuplicateRowButton = false,
    hideHeader,
    addMoreLabel,
    onEnterPress,
    formState,
    setFormState,
    ...props
}) => {
    const headerLabels = fieldsConfig.map((field) => {
        return { label: field.label, flex: field.flex, type: "field" };
    });

    const totalFlex = fieldsConfig.map((val) => val.flex).reduce((partialSum, a) => partialSum + a, 0);

    if (showDeleteRowButton) {
        headerLabels.unshift({ label: "", flex: 0.5, type: "action" });
    }

    const addNewRow = () => {
        const newData = [...formState].concat([oneEmptyRow]);
        setFormState(newData);
    };

    const removeRow = (index) => {
        const newData = [...formState].filter((_, i) => i !== index);
        setFormState(newData);
    };

    const oneEmptyRow = fieldsConfig.map((field) => {
        return { ...field };
    });

    const getFieldValue = (name, index) => {
        return (
            formState
                .find((row, i) => index === i)
                .find((val) => val.name === name).value || ""
        );
    };

    const setFieldValue = (fieldName, index, value) => {
        setFormState((prevState) =>
            prevState.map((row, i) =>
                i === index ? row.map((field) => (field.name === fieldName ? { ...field, value } : field)) : row
            )
        );
    };

    const getFormValues = (formState) => {
        return formState
            .map((row) => {
                return row.reduce((acc, field) => {
                    if (field.value) {
                        if (field.formatBeforeSubmit) {
                            acc[field.name] = field.formatBeforeSubmit(field.value, row);
                        } else {
                            acc[field.name] = field.value;
                        }
                    }
                    return acc;
                }, {});
            })
            .filter((val) => Object.keys(val).length);
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (onEnterPress) {
                onEnterPress();
            }
        }
    };

    const renderField  = (field, index) => {
        switch (field.type) {
            case "input":
                return (
                    <input
                        type={field.subType}
                        className="sio-form-input"
                        id={"sio-cfm-" + field.name + "-" + index}
                        value={getFieldValue(field.name, index)}
                        onChange={(e) => {
                            setFieldValue(field.name, index, e.target.value);
                        }}
                        autoComplete="off"
                        onKeyDown={onEnterPress && onKeyDown}
                        name={field.name}
                        data-lpignore="true"
                    />
                );
                break;
            case "select":
                return (
                    <Select
                        name={"sio-cfm" + field.name + index}
                        choiceOptions={field.selectOptions}
                        onChange={(e) => {
                            setFieldValue(field.name, index, e.value);
                        }}
                        value={getFieldValue(field.name, index)}
                        isMulti={false}
                        key={uuidv4()}
                    />
                );
                break;
            case "static":
                return (
                    <div
                        className="sio-form-input"
                        id={"sio-cfm" + field.name + index}
                        style={{ backgroundColor: "lightyellow" }}
                    >
                        {getFieldValue(field.name, index)}
                    </div>
                );
                break;
            default:
                return <div className="sio-form-input">test</div>;
                break;
        }
    };

    // for setting default empty rows
    useEffect(() => {
        const defaultRows = Array(defaultEmptyRows).fill(0);
        const defaultFormState = defaultRows.map(() => oneEmptyRow);
        setFormState(defaultFormState);
    }, []);
    // for sync form data with redux field
    useEffect(() => {
        const data = formState.map((row) =>
            row.map((field) => (field.hideInFormValue ? { ...field, value: undefined } : { ...field }))
        );
        props.input.onChange(getFormValues(data || formState));
    }, [formState]);

    return (
        <div className="contineous-form">
            <div className="cf-header" style={{ display: "flex", flexWrap: "nowrap", gap: "6px" }}>
                {/* header for contineous form */}
                {!hideHeader &&
                    headerLabels.map((label, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    width:
                                        label.type !== "action"
                                            ? (label.flex / totalFlex) * 100 + "%"
                                            : ACTION_BUTTON_WIDTH,
                                }}
                            >
                                <label className="cf-form-label">{label.label}</label>
                            </div>
                        );
                    })}
            </div>

            {/* Field Map */}
            <div className="cf-data-rows">
                {formState.map((oneRow, index) => {
                    return (
                        <div
                            key={index}
                            className="cf-dataone-row"
                            style={{ display: "flex", flexWrap: "nowrap", gap: "6px", marginBottom: "3px" }}
                        >
                            {showDeleteRowButton && (
                                <div tabIndex={-1} style={{ width: ACTION_BUTTON_WIDTH }}>
                                    <button
                                        tabIndex={-1}
                                        onClick={() => {
                                            removeRow(index);
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                    >
                                        x
                                    </button>
                                </div>
                            )}
                            {oneRow.map((field, i) => {
                                return (
                                    <div
                                        key={i}
                                        style={{
                                            gap: "6px",
                                            width: (field.flex / totalFlex) * 100 + "%",
                                        }}
                                    >
                                        {renderField(field, index)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <button
                    className="btn btn-primary "
                    style={{ margin: "auto", marginTop: "6px", display: "flex" }}
                    onClick={addNewRow}
                    type="button"
                >
                    {addMoreLabel ?? "mehr hinzufügen"}
                </button>
            </div>
        </div>
    );
};

export default ContineousForm;
