import React, {  useEffect, useState } from "react";
import { Field } from "redux-form";
import ContineousForm from "../fields/ContineousForm";
import { formatCurrency, formatLongDate, isUpdateable } from "../functions/fucntions";


const PersonsInfoForm = ({ sendRequest, response, ...props }) => {
    const [formState, setFormState] = useState([]);
    const fetchedPersons = response?.body?.persons || false;
    const fetchedCode = Number(response?.body?.code) || 0;

    const contineousFormFieldConfig = [
        {
            name: "salutation",
            label: "Anrede",
            flex: 1,
            type: "select",
            selectOptions: [
                { value: "female", label: "Frau" },
                { value: "male", label: "Herr" },
                { value: "unspecified", label: "divers" },
            ],
            sendRequest: sendRequest,
        },
        { name: "firstName", label: "Vorname", flex: 1, type: "input", subType: "text", sendRequest: sendRequest },
        { name: "lastName", label: "Nachname", flex: 1, type: "input", subType: "text", sendRequest: sendRequest },

        { name: "birthdate", label: "Geburtsdatum", flex: 1, type: "input", subType: "date", sendRequest: sendRequest },
        { name: "price", label: "Preis", flex: 1, type: "static", hideInFormValue: true },
    ];

    const updatePreviousData = (previousRowsData, fetchedData) =>
        previousRowsData.map((row, i) => {
            const fetchedPrice = fetchedPersons[0]?.price;
            const persons = fetchedPersons.length || 0;
            if (fetchedPrice && i < persons) {
                return row.map((field, i) =>
                    field.name === "price" ? { ...field, value: formatCurrency(fetchedPrice) } : { ...field }
                );
            } else {
                return row.map((field, i) => (field.name === "price" ? { ...field, value: undefined } : { ...field }));
            }
        });

    const overideNewData = (fetchedData) =>
        fetchedData.map((data) =>
            contineousFormFieldConfig.map((field) => {
                const fieldValue = data[field.name];
                if (fieldValue !== undefined) {
                    return {
                        ...field,
                        value:
                            field.name === "price"
                                ? formatCurrency(fieldValue)
                                : field.name === "birthdate"
                                ? formatLongDate(fieldValue, true)
                                : fieldValue,
                    };
                }
                return field;
            })
        );

    useEffect(() => {
        if (fetchedPersons) {
            if (isUpdateable(fetchedCode)) {
                setFormState((previousRowsData) => updatePreviousData(previousRowsData, fetchedPersons));
            } else if (fetchedPersons.length > 0) {
                setFormState(overideNewData(fetchedPersons));
            }
        }
    }, [fetchedPersons]);

    return (
        <div className="sio-services " style={{ overflowY: "auto" }}>
            <Field
                id="persons"
                name="persons"
                onEnterPress={sendRequest}
                defaultEmptyRows={7}
                fieldsConfig={contineousFormFieldConfig}
                addMoreLabel={"Hinzufügen"}
                component={ContineousForm}
                showActions={true}
                showCloseButton={false}
                formState={formState}
                setFormState={setFormState}
                {...props}
            />
        </div>
    );
};

export default PersonsInfoForm;
