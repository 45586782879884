import React, {useEffect} from "react";


let Input= ({
    id,
    label,
    placeholder,
    hideLabel,
    subType = "text",
    min,
    defaultValue,
    ...props
}) => {
    const onChange = (value) => {
        const cleanValue = value.target.value;
        props.input.onChange(cleanValue.length == 0 ? null : cleanValue.toUpperCase());
    };

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (props?.onEnterpress && props.input.value.length !== 0) {
                props.onEnterpress();
            }
        }
    };

    useEffect(() => {
        if(defaultValue){
            props.input.onChange(defaultValue);
        }
    }, [defaultValue])


    return (
        <div className={`sio-input text-input ${props.class ?? ""}`}>
            {!hideLabel && (
                <label className="sio-form-label" htmlFor={id}>
                    {label}
                </label>
            )}
            <input
                type={subType}
                className="sio-form-input"
                id={id}
                value={props.input.value}
                autoComplete="off"
                onChange={onChange}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                min={min}
                data-lpignore="true"
            />
        </div>
    );
};

export default Input;
